<template>
  <Portlet
    :title="'Report'"
    icon="receipt"
    class="mediaReviewReport"
  >
    <MediaReviewReportList
      ref="meidaReviewReportList"
    /> 
  </Portlet>
</template>

<script>
export default {
    name: 'MediaReviewReport',
    components: {
        MediaReviewReportList: () => import('@/components/MediaReview/MediaReviewReportList.vue'),
    },
    metaInfo () {
        return {
            title: 'Report'
        }
    },
    methods: {
      
    }
}
</script> 